@import "pyrat_basic";

// Special style for the language widget
#lang_select_wrapper { position: absolute !important; }

#lang_select_div {
    border-radius: 5px;
    padding: 7px;
    background-color: $basic_color_500;
    width: 23px;

    &.mouse_over,
    &.popup_visible {
        background-color: $basic_color_700;
    }
}

.lang_box {
    border-radius: 10px 10px 10px 0;
    margin-top: 5px;
}

// This colors are for setting in the set alias when user has many animals in cage or other
// situation to attend with certain level of careness
option { background-color: $contrast-color; }

#new_alias {
    .green {
        background-color: $basic_color_300;
    }
    .yellow {
        background-color: lighten($yellow-color, 5);
    }
    .red {
        background-color: lighten($light-red, 10);
    }
}
// ----------------------

html, body {
    height: 100%;
}

// A container for everything that is in the login site (header, form and footer)
.login_container {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.red, &.blue, &.yellow, &.violet, &.purple {
        height: calc(100% - 10px);
    }

    &.red {
        border-top: 10px solid $error-color;
    }
    &.blue {
        border-top: 10px solid $second_color_900;
    }
    &.yellow {
        border-top: 10px solid $yellow-color;
    }
    &.violet {
        border-top: 10px solid $violet-color;
    }
    &.purple {
        border-top: 10px solid $purple-color;
    }

    .login_header {
        text-align: center;

        .main_logo_container {
            width: fit-content;
            margin: auto;

            img {
                margin: auto;
            }
        }
    }
}


// Main box containing the core elements (including the flag widget)
.login_form {
    background-color: $shade-color-93;
    color: $shade-color-30;
    border: 1px solid $shade-color-82;
    padding: 10px;
    width: 600px;
    align-self: center;

    .form,
    .reset_pass_form {
        margin-top: 3.5em;
    }

    p {
        color: $second_color_900;
        font-size: $large-font-size;
        text-align: center;
    }

    .welcome_message,
    .login_message {
        font-size: $medium-bigger-font-size;
        line-height: 22px;
        overflow: auto;
        text-align: center;
        white-space: pre-wrap;
    }

    .welcome_message {
        max-height: 175px;
        margin-bottom: 10px;
    }

    .login_message {
        margin-bottom: 0;
        max-height: 125px;
    }

    .title_message {
        background-color: $basic_color_500;
        color: $contrast-color;
        font-size: $medium-bigger-font-size;
        font-weight: bold;
        padding: 6px;
        margin: 0 -10px;
        text-align: center;
        white-space: nowrap;

        span {
            margin-left: 8px;
        }
    }

    .login_content {
        margin-bottom: 15px;

        .button_login {
            text-align: center;
            margin: 10px;
            padding-bottom: 10px;

            input {
                margin: 0 5px;
            }
        }
        .sso_login {
            padding: 10px;

            a {
                font-weight: bold;
                padding: 3px 7px;
            }
        }
    }

    // Error message when user is not authorized to log in as any scientists
    .error {
        background: url('../images/error.png') no-repeat scroll left center / 28px auto transparent;
        padding-left: 30px;
        color: $high-level-color;
        font-size: $medium-font-size;
    }
}

.login_form_box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .forgotten_password_text {
        padding: 10px 0;
        color: $second_color_900;
        text-align: center;
        margin: 5px;
        font-weight: bold;
        font-size: $extra-large-font-size;
    }
}

.texthead {
    font-size: $medium-font-size;
    padding: 10px 0;
    text-align: center;
    font-weight: $base-font-weight;
    margin: 8px 0;
}

.login_inputs {
    padding: 0.25rem;

    .login_row {
        display: flex;
        align-items: center;

        .login_title_row {
            flex-basis: 40%;
            padding: 0.25rem;
            text-align: right;

            label {
                font-weight: 700;
                font-size: $large-font-size;
                line-height: 2rem;
                text-align: right;
            }
        }

        .login_data_row {
            flex-basis: 58.333%;
            padding: 0.25rem;
        }
    }
}

.login_form table.login_table {
    margin: auto;

    tr {
        height: 40px;
        margin: auto;
        font-size: $large-font-size;

        td.first_td {
            padding-right: 7px;
            text-align: right;
            font-weight: bold;
        }

        td #new_alias { max-width: 225px; }
    }

    input[type="text"],
    input[type="password"] {
        width: 200px;
    }
}

.forgot_password .loginlink {
    color: $shade-color-30;
    line-height: 30px;
    padding: 10px;

    &:hover,
    &:active {
        color: $basic_color_900;
    }
}

.login-back-link {
    transition: all 0.2s ease-in-out;
    font-size: $base-font-size;
    padding: 6px;

    &.allways_visible {
        background-color: $warning-color;
        color: $contrast-color;
    }

    &:hover,
    &:active {
        border-radius: 3px;
        background-color: $basic_color_600;
        color: $contrast-color;
    }

    span.icon_button {
        line-height: 0;
        vertical-align: middle;
    }
}

.not_allow_error {
    font-weight: bold;
    color: $high-level-color;
    padding: 5px;
}

.back_log { padding: 10px; }

.login_footer {
    color: $shade-color-30;
    font-size: $body-font-size;
    line-height: 19px;
    margin-top: 25px;
    text-align: center;
    width: 100%;

    .scionics_logo_container {
        width: fit-content;
        margin: auto;

        img {
            width: 200px;
            margin: auto;
        }
    }

    .logos {
        height: 70px;
        margin: 5px auto 0;
        padding: 5px 10px 2px;
        width: 375px;
    }

    .pyrat_logo {
        border-right: 1px solid $shade-color-30;
        float: left;
        padding-right: 30px;

        img { height: 60px; }
    }

    .scionics_logo {
        float: right;
        padding-left: 30px;

        p { margin: 0;
 }

        img { height: 45px;
 }
    }
}
